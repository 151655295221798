@import "01-Variables/colors";
@import "01-Variables/transitions";
@import "01-Variables/shadows";
@import "01-Variables/typography";
@import "02-Components/navbar";
@import "02-Components/btn";
@import "02-Components/image";

$primary: $primary;
$secondary: $yellowe;

@import "node_modules/bulma/bulma";

body {
  min-height: 100vh;
  background: white; }

.card {
  border: none;
  position: relative;

  .card-pill {
    position: absolute;
    background-color: $white;
    color: $primary;
    display: inline-flex;
    padding: 0.8em 2em;
    border-radius: 200px;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 0.8rem;
    top: 0.75em;
    left: 0.75em;
    box-shadow: $shadow-3; }

  figure {
    display: flex;
    & > img {
      object-fit: cover; } } }

.container {
  @media screen and (max-width: 768px) {
    max-width: 95%; } }

hr {
  &.no-margin-top {
    margin-top: 0; } }

#intro-section {
  position: relative;
  padding-bottom: 5em;
  #santa {
    position: absolute;
    top: 85%;
    left: 10vw;
    height: 250px;
    z-index: 2;
    @media screen and (max-width: 1088px) {
      top: 95%;
      left: 5vw; }
    @media screen and (max-width: 768px) {
      top: 100%;
      left: -40px;
      height: 100px; } } }

#storitve {
  .column {
    align-self: stretch;
    .card {
      height: 100%;
      border-radius: 10px;
      overflow: hidden; } } }

#digitalno {
  margin-top: 50px; }

#nagovor,
#digitalno {
  position: relative;

  #candy {
    position: absolute;
    bottom: 0;
    height: 200px;
    right: 15vw;
    transform: translateY(50%);
    @media screen and (max-width: 768px) {
      height: 100px; } } }

#dobri-mozje {
  padding: 0;
  .container {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 575px) {
      align-items: flex-end; } }

  img {
    flex: 1;
    object-fit: contain;
    @media screen and (max-width: 575px) {
      width: 33%; } }

  img:nth-child(1) {
    transform-origin: 50% 100%;
    transform: translateX(25%) scale(0.85); }

  img:nth-child(2) {
    z-index: 5; }

  img:nth-child(3) {
    transform-origin: 50% 100%;
    transform: translateX(-20%) scale(0.85); } }

#zakljucek {
  margin-top: 75px; }

iframe {
  height: 650px;
  max-height: 70vh;
  @media screen and (max-width: 768px) {
    max-height: 50vh !important; } }

footer {
  padding: 1em 1em .5em;
  .info {
    padding: 1em;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    @media screen and (max-width: 860px) {
      flex-direction: column;
      align-items: center; }
    a {
      color: $primary;
      &:hover {
        color: darken($primary, 5%); } } }

  .legal {
    padding: .5em;
    font-size: .85em;
    text-align: center; } }
