$main-font: 'Oswald', sans-serif;
$heading-font: 'Sigmar One', cursive;

h1,
h1.title,
h2.title {
  font-family: $heading-font;
  font-size: 3.2em;
  @media screen and (max-width: 768px) {
    font-size: 2.5em; } }

h3.title {
  margin-bottom: 0 !important;
  font-family: $heading-font;
  @media screen and (max-width: 860px) {
    text-align: center; } }
