.btn {
  font-size: 1.1rem;
  padding: 0.75em 1.25em;
  border-radius: 0.2em;
  margin-top: 0.5em;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  border: 2px solid transparent;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center; }

  &:not(:first-of-type) {
    margin-left: 0.5em;

    @media screen and (max-width: 768px) {
      margin-left: 0; } }

  &--outlined {
    &--white {
      border-color: $white;
      color: $white;

      &:hover {
        background-color: transparentize($white, 0.7);
        color: $white; } } }

  &--white {
    background-color: $white;
    color: $primary; } }
