nav {
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  & > .social {
    display: flex;
    a {
      font-size: 1.25em;
      margin: 0 .5em;
      padding: .75em;
      border: 4px solid $primary;
      color: $primary;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      @include easeTransition(500ms);
      &:hover {
        color: white;
        border-top-left-radius: 50%;
        border-top-right-radius: 0;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background: $primary; } } }

  #christmas-star {
    position: absolute;
    top: 90%;
    left: 50px;
    height: 200px;
    @media screen and (max-width: 1400px) {
      height: 100px; } }

  #christmas-bell {
    position: absolute;
    top: 50%;
    right: 50px;
    height: 200px;
    @media screen and (max-width: 1400px) {
      height: 150px;
      top: 65%; }
    @media screen and (max-width: 450px) {
      height: 100px; } } }
