.img-container {
  border-radius: .5rem;
  overflow: hidden;
  box-shadow: $shadow-5;
  display: flex;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%; } }
